body {
  background-color: #161616;
  color: white;
  font-family: 'Roboto', sans-serif;
}

.App {
  text-align: center;
  padding: 20px;
  background-color: #161616; /* Dark grey instead of black */
  color: #dcdcdc; /* Light grey text for better readability */
  align-items: center;
}

.App h1 {
  font-size: 2.5em; /* Increase the font size */
  color: #1ca3cc; /* Bright color for the text */
  /*text-shadow: 0 0 10px #1ca3cc; /* Glowing effect */
  margin-bottom: 30px; /* Add some space below the title */
  text-align: center; /* Center the title */
}

h2 {
  color: #4ac356;
  margin: 30px 0 20px;
}

.refresh-button {
  background-color: #1ca3cc;
  font-size: large;
  color: black;
  border: 2px solid rgba(255, 255, 255, 0.1); /* Off-white border */
  padding: 15px 40px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 10px;
}

.refresh-button:hover {
  background-color: rgba(3, 242, 255, 0.882);
}

.refresh-button:active {
  transform: scale(0.95);
}

.options-section {
  margin-bottom: 40px;
}


.options-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.option-item {
  background: rgba(255, 255, 255, 0.05); /* Slightly visible background */
  border: 1px solid rgba(255, 255, 255, 0.1); /* Subtle border */
  border-radius: 10px;
  padding: 15px;
  width: 250px; /* Adjust width as needed */
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; /* Smooth transition for hover effects */
}

.option-item:hover {
  transform: translateY(-5px); /* Slightly raise the card */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5); /* Softer shadow */

}

.metric {
  margin: 10px 0;
  color: #fff; /* White text for readability */
}

.metric span {
  font-weight: bold;
  color: #1ca3cc; /* Make the data stand out */
}

.ticker span {
  font-size: 1.2em;
  color: #1ca3cc;
}

.loader-container {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.metric {
  margin: 10px 0;
  color: #fff; /* White text for readability */
}

/* General style for the score pill */
.score-pill {
  display: inline-block;
  padding: 5px 10px; /* Adjust the padding to create a pill shape */
  border-radius: 15px; /* Fully rounded edges for pill shape */
  font-weight: bold;
  font-size: 1.2em; /* Increase font size */
  color: #fff; /* White text */
  margin-top: 10px; /* Add some space from the other elements */
  position: relative; /* To position it relative to its parent */
  z-index: 10; /* Ensure it's above other elements */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Optional: add some depth with a shadow */
  transition: transform 0.3s; /* For animation */
  text-align: center;
  min-width: 50px; /* Ensures a pill shape even with small numbers */
}

/* Score pill for calls */
.call .score-pill {
  background-color: #1ca3cc; /* Soft green for calls */
}

/* Score pill for puts */
.put .score-pill {
  background-color: #e57373; /* Soft red for puts */
}

/* Optional: Hover effect to grab attention */
.score-pill:hover {
  transform: scale(1.1); /* Grow on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7); /* Larger shadow for depth */
}

.icon {
  margin-left: 5px; 
  margin-top: 3px;
}
.diamond {
  color: #7DF9FF; /* Diamond color */
  animation: pulse 2s infinite linear;
}
@keyframes pulse {
  0%, 100% { transform: scale(.9); }
  50% { transform: scale(1.3); } /* Slightly larger at the midpoint */
}

.gold {
  color: #ffd700; /* Gold color */
}

.silver {
  color: #c0c0c0; /* Silver color */
}


.icon.trophy {
  color: #52ff14; /* Purple color for the trophy icon */
  
}

.star {
  color: #e5ff00; /* A vibrant shade of purple */
  transform: scale(1.5); /* Make the icon larger */
  animation: spin 2s infinite linear, shine 2s infinite linear; /* Add multiple animations */
  margin-left: 5px;
}

@keyframes spin {
  from { transform: scale(1.5) rotate(0deg); }
  to { transform: scale(1.5) rotate(360deg); }
}


@keyframes shine {
  0% { opacity: 1; }
  50% { opacity: 0.5; } /* Dimmer at the midpoint */
  100% { opacity: 1; }
}

.progress-container {
  width: 25%;
  background-color: #ccc;
  border-radius: 5px;
  overflow: hidden;
  margin: 0 auto; /* Center the progress bar */
}

.progress-bar {
  height: 20px;
  background-color: #1ca3cc;
  width: 0; /* Initially set to 0 */
  transition: width 0.1s ease-in-out; /* Smooth transition for width changes */
}

.status-message {
  margin-top: 10px;
  color: #1ca3cc;
  text-align: center; /* Center the status message */
}

.rank-badge {
  position: absolute; /* Position the rank badge absolutely within the option item */
  top: 5px; /* Position from the top */
  left: 5px; /* Position from the left */
  background-color: #4ac356; /* Background color of the badge */
  color: #000; /* Text color */
  font-weight: bold; /* Make the rank number bold */
  padding: 2px 5px; /* Padding inside the badge */
  border-radius: 50%; /* Round the corners of the badge */
  font-size: 0.8em; /* Adjust font size */
}

.option-item {
  position: relative; /* Required for absolute positioning of children */
  border: 1px solid rgb(104, 104, 104); /* Optional: adds a border */
  padding: 20px; /* Adjust based on your content */
  border-radius: 5px; /* Optional: rounds corners */
  margin-bottom: 15px; /* Space between items */
}


.legend-section {
  text-align: center;
  margin-top: 10px;
}

.legend-container {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px;
  background: rgba(255, 255, 255, 0.1); /* subtle background for each legend item */
  border-radius: 5px;
}

.legend-range {
  font-weight: bold;
  color: #1ca3cc;
}

.legend-description {
  color: #fff;
  font-size: 0.9em; /* slightly smaller font size for the description */
}

.legend-heading {
  color: #1ca3cc; /* Sets the text color to green */
  margin-bottom: 10px; /* Adds some space below the heading */
  display: inline-block; /* Allows the underline to fit the content width */
}

.legend-title-box {
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.1); /* Or any color you prefer */
  color: #1ca3cc; /* Green text color */
  padding: 5px 10px; /* Adjust padding to your liking */
  border-radius: 5px; /* Rounded corners */
  margin-bottom: 10px; /* Space below the title */
}

.button-with-tooltip {
  display: inline-flex; /* Align items in a row */
  align-items: center; /* Center items vertically */
}

.refresh-button {
  /* Your existing styles */
  margin-right: 0px; /* Add some space between the button and the icon */
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-icon {
  font-size: 24px; /* Icon size */
  margin-top: -35px;
  color: #1ca3cc; /* Icon color */
  cursor: pointer; /* Change cursor to indicate it's interactive */
}

.popup {
  position: absolute;
  top: 43px; /* Adjust based on your layout */
  left: 78%; /* Center the popup */
  transform: translateX(-50%); /* Center the popup */
  padding: 10px 20px;
  background-color: #333; /* Popup background */
  color: #1ca3cc; /* Text color */
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add some shadow for depth */
  z-index: 10; /* Ensure the popup is above other elements */
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.header-section {
  display: flex;
  align-items: center; /* Vertically center the items */
  justify-content: center; /* Horizontally center the items */
  position: relative; /* Needed for absolute positioning of pseudo-element */
  margin-top: -10px;
}

.header-section::after {
  content: '';
  position: absolute;
  bottom: 20px; /* Adjust this value to move the line up or down */
  left: 50%; /* Start from the middle */
  transform: translateX(-50%); /* Center the pseudo-element */
  width: 70%; /* Adjust the width as per your design preference */
  height: 2px;
  background-color: #1ca3cc; /* The color of the divider */
}

.dashboard-title {
  margin-right: 10px; /* Add some space between the title and the icon */
  margin-top: -10px;
}

.info-icon-container {
  position: relative;
  display: inline-flex; /* Use inline-flex to keep the icon aligned with the title */
  align-items: center; /* Vertically center the icon */
}

.info-icon {
  cursor: pointer;
}

.popup {
  visibility: hidden;
  position: absolute;
  width: 200px;
  top: 150%;
  left: 50%;
  transform: translateX(-50%);
  /* Additional styling for the popup */
}

.info-icon-container:hover .popup {
  visibility: visible;
}


.navbar {
  background-color: #161616; /* Slightly lighter dark background */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-bottom: .5px solid #1ca3cc;
  transition: background-color 1s, border-color 0.3s;

}

.navbar-title {
  position: absolute; /* Position the title absolutely within the navbar */
  left: 50%; /* Set to the halfway point of the navbar */
  transform: translateX(-50%); /* Shift it back by half its width */
  color: #1ca3cc;
}


.nav-links {
  display: flex;
  gap: 20px; /* This sets equal space between your navigation items */
  list-style-type: none;
  padding-left: 0;
  
  
}

.nav-links li {
  position: relative; /* Needed for absolute positioning of dropdown */
  border: 2px solid transparent;

}

.nav-links li a {
  padding: 10px 15px; /* Match the tab buttons padding */
  color: #dcdcdc; /* Light grey for readability */
  text-decoration: none;
  display: block; /* To fill the area for a larger clickable region */
  border: 2px solid transparent; /* Invisible border to maintain layout */
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
  border-radius: 5px;

}

.nav-links li a:hover,
.nav-links li a.active-link {
  color: #FFFFFF; /* White for active or hovered link */
  background-color: #1ca3cc33; /* Transparent background for hover/active */
  /*border-color: #1ca3cc33; /* Border color transition on hover */
  border-color: #1ca3cc;
}


.nav-links li a.active{
  border-color: #1ca3cc; /* Border color changes instead of background */

}

/* Dropdown Menu */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #333;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 5px;
  top: 100%;
}

.dropdown-content a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  transition: background-color 0.3s;
}

.dropdown-content a:hover {
  background-color: #1ca3cc; /* Highlight color for dropdown items */
}

.nav-links li:hover .dropdown-content {
  display: block;
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
  }

  .nav-links {
    flex-direction: column;
    width: 100%; /* Full-width nav links for mobile */
  }

  .dropdown-content {
    width: 100%; /* Full-width dropdowns on mobile */
  }

  .nav-links li a {
    width: 100%; /* Full-width links for mobile */
    text-align: center; /* Center text for mobile */
  }
}



html {
  scroll-behavior: smooth;
}

/* CSS classes for Call and Put options */
.call {
  /* Style for call options */
  color: #4ac356;
}

.put {
  /* Style for put options */
  color: #ff6666;
}


.call-options-title{
  color: #4ac356; /* Green color for call options title */
}

.put-options-title {
  color: #ff6666; /* Red color for put options title */
}

.call-option {
  /* Style for call option boxes */
  /*border-left: 3px solid #4ac356; /* Green border on the left */
  text-decoration: none; /* Ensures underline doesn't appear on hover */

}

.put-option {
  /* Style for put option boxes */
  /*border-left: 3px solid #ff6666; /* Red border on the left */
  text-decoration: none; /* Ensures underline doesn't appear on hover */

}

/* If you want to change the text color as well */
.call-option .metric span, .call-option .ticker span {
  color: #4ac356; /* Green text for call options */
  text-decoration: none; /* Ensures underline doesn't appear on hover */

}

.put-option .metric span, .put-option .ticker span {
  color: #ff6666; /* Red text for put options */
  text-decoration: none; /* Ensures underline doesn't appear on hover */

}

.ticker-link {
  text-decoration: none; /* Removes underline */
  color: inherit; /* Keeps the color consistent with the parent element */
}

.ticker-link:hover {
  text-decoration: none; /* Ensures underline doesn't appear on hover */
  /* Optional: add other hover effects, like changing color */
}

/* Style for call option ranking badge */
.call-option .rank-badge {
  background-color: #4ac356; /* Green background for call options */
  color: black; /* Text color */
  /* Add other styling properties as needed */
}

/* Style for put option ranking badge */
.put-option .rank-badge {
  background-color: #ff6666; /* Red background for put options */
  color: black; /* Text color */
  /* Add other styling properties as needed */
}

.call .score-value {
  background-color: #4ac356; /* or any green shade you prefer */
  color: black; /* text color inside the pill */
  padding: 3px 6px;
  border-radius: 10px;
  font-weight: bold;
}

.put .score-value {
  background-color: #ff0033; /* or any red shade you prefer */
  color: black; /* text color inside the pill */
  padding: 3px 6px;
  border-radius: 10px;
  font-weight: bold;
}

/* Base Styles */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #121212; /* Dark background for a modern look */
  color: #ffffff; /* White text for contrast */
}

.home-container {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

.home-header h1 {
  font-size: 2.5rem;
  color: #1ca3cc; /* Bright blue for a pop of color */
  margin-bottom: 10px;
}

.home-header p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}
.features-overview-title {
  color: #1ca3cc; /* Light green for the title */
  margin-bottom: 20px; /* Space between title and boxes */
  font-size: 24px; /* Adjust font size as needed */
}
/* Features Overview Section */
.features-overview {
  display: flex;
  justify-content: center; /* This will center the items */
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 50px;
}

.feature-item {
  background: rgba(255, 255, 255, 0.1); /* Semi-transparent cards */
  padding: 20px; /* Increase padding for better spacing */
  border-radius: 10px; /* Rounded corners */
  margin: 20px; /* Add margin to create space around cards */
  flex: 0 1 calc(33.333% - 40px); /* Adjust flex-basis for 3 items per row with margins */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Softer shadow for depth */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effects */
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; /* For positioning pseudo-elements or children */
}

.feature-item::before {
  content: '';
  position: absolute;
  top: -10px; /* Slightly offset from the top */
  left: 0;
  right: 0;
  height: 5px; /* Thin bar at the top of the card */
  background: linear-gradient(to right, #1ca3cc, #ff6a00); /* Gradient bar */
  border-radius: 5px 5px 0 0; /* Rounded top corners */
}

.feature-item:hover {
  transform: translateY(-5px); /* Move up on hover */
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.25); /* Larger shadow for 'lifting' effect */
}

.feature-item h3 {
  color: #1ca3cc; /* Bright color for titles */
  font-size: 1.25em; /* Larger font size */
  font-weight: bold; /* Bold font weight */
  margin-bottom: 15px; /* Space below the title */
}

.feature-item p {
  font-size: 1em; /* Appropriate font size for readability */
  line-height: 1.5; /* Increased line-height for better readability */
  margin-bottom: 15px; /* Space below the paragraph */
}

.feature-icon {
  font-size: 3em; /* Larger icon size */
  margin-bottom: 15px; /* Space below the icon */
  color: #1ca3cc; /* Icon color to match the theme */
}

/* Interactive Tour and Video Preview */
.video-preview img {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.preview {
  margin-top: 40px;
}

.testimonials {
  margin-top: 40px;
}

/* Contact Information in Footer */
footer {
  background-color: #161616; /* Slightly lighter than body for distinction */
  padding: 20px;
  border-top: 1px solid #333; /* Subtle border for separation */
  text-align: center;
}

/* Responsive Design for smaller screens */
@media (max-width: 768px) {
  .features-overview {
    flex-direction: column;
  }

  .feature-item {
    margin-bottom: 20px;
  }
}

/* Button and Link Styles */
a {
  color: #1ca3cc;
  text-decoration: none; /* Removes underline */
}

a:hover {
  color: #ffffff; /* White text on hover for more emphasis */
}

/* Generic Section Titles */
h3 {
  border-bottom: 1px solid #333; /* Adds a subtle line under section titles */
  padding-bottom: 10px;
  color: #1ca3cc;
}


.contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

@media (max-width: 768px) {
  .navigation {
    flex-direction: column;
  }
  
  .nav-button {
    margin-bottom: 10px;
  }
}

.navbar {
  display: flex;
  justify-content: space-between; /* This will separate the nav links and the Slack icon */
  align-items: center;
  /* Add other styles for your navbar here */
}

.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
  /* Add other styles for your nav-links here */
}

.slack-link-container {
  /* Styles for the Slack link container if needed */
}

.slack-icon {
  color: #1ca3cc; /* Slack brand color */
  font-size: 36px; /* Adjust the size as needed */
}

.slack-icon:hover {
  color: #E01E5A; /* Slack hover color */
}

.scores-container {
  margin: 20px auto;
  padding: 20px;
  max-width: 800px; /* Adjusted for better spacing */
  background-color: #0f0f0f; /* Darker background for contrast */
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.5); /* Stronger shadow for depth */
  color: #f1f1f1; /* Lighter text for better readability */
}

.scores-container h2 {
  color: #1ca3cc; /* Green to match the theme */
  text-align: center;
  margin-bottom: 1rem; /* Added space below heading */
}

.scores-section {
  margin-bottom: 1rem; /* Added space between sections */
}

.scores-list {
  list-style: none;
  padding: 0;
  margin: 0; /* Reset margin for consistency */
}

.scores-list li {
  background-color: rgba(255, 255, 255, 0.05); /* Subtle box for each score */
  border-left: 5px solid #1ca3cc; /* Green accent line */
  padding: 0.5rem 1rem; /* Increased padding */
  margin-bottom: 0.5rem; /* Decreased space between items */
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: transform 0.2s ease-in-out; /* Smooth hover effect */
}

.scores-list li:hover {
  transform: translateY(-2px); /* Lift effect on hover */
}

.scores-list li:last-child {
  margin-bottom: 0; /* Remove margin from the last item */
}

.ticker {
  font-size: 1rem; /* Increased font size */
  font-weight: bold; /* Bold font for tickers */
}

.score {
  font-size: 1.5rem; /* Larger font size for scores */
  font-weight: bold; /* Bold font for emphasis */
  color: #fff; /* White color for scores to pop */
  text-shadow: 0 2px 4px rgba(0,0,0,0.3); /* Text shadow for depth */
}

/* Optional: Style for the loading spinner */
.spinner {
  border: 4px solid rgba(0,0,0,0.1);
  border-left-color: #1ca3cc;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.tabs {
  display: flex;
  justify-content: center;
  margin-top: -10px;
  position: relative;
  
}

.tabs-border {
  width: 20%; /* change to the width you desire */
  margin: 0 auto; /* center the border */
}


.tab-button {
  padding: 10px 15px; /* Slightly reduce padding */
  border: 2px solid transparent; /* Invisible border that becomes visible when active */
  background-color: #1ca3cc33; /* More transparent background */
  color: #dcdcdc;
  cursor: pointer;
  margin: 0 10px; /* Increase space between tabs */
  border-radius: 5px;
  outline: none;
  transition: color 0.3s, border-color 0.3s, background-color 0.3s;
}

.tab-button:hover,
.tab-button.active {
  color: #FFFFFF; /* Brighter color on hover and for active tab */
  border-color: #1ca3cc; /* Border color changes instead of background */
}

/* On active tab, only change text color and border */
.tab-button.active {
  background-color: #1ca3cc33; /* Remove background color change */
  color: #FFFFFF; /* Active tab text color */
}


.stock-chart-container {
  max-width: 1400px; /* Increase the maximum width */
  margin: 30px auto; /* Ensure it's centered with more margin */
  padding: 20px;
  text-align: center;
}

/* Control the size of the chart explicitly */
.chart-container {
  width: 100%; /* Full width of its parent container */
  max-width: 600px; /* Adjust this to control the chart's max width */
  margin: 0 auto; /* Center the chart */
}

@media (max-width: 768px) {
  .stock-chart-container {
    max-width: 100%; /* Full width on smaller screens */
  }
}

.stock-controls {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

/* Style the input field */
.stock-input {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #1ca3cc;
  border-radius: 5px;
  color: #1ca3cc;
  background-color: #333;
}

/* Style the select dropdown */
.timeframe-select {
  padding: 10px;
  font-size: 16px;
  border: 2px solid #1ca3cc;
  border-radius: 5px;
  color: #1ca3cc;
  cursor: pointer;
  background-color: #333;
}

.loader, .error-message {
  color: #1ca3cc;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .stock-controls {
    flex-direction: column;
  }

  .stock-input, .timeframe-select {
    width: 100%;
    margin-bottom: 10px;
  }
}

.h3 {
  color: #1ca3cc;
}

.chat-container {
  max-width: 1600px;
  height: 500px;
  overflow-y: auto;
  margin: auto;
  text-align: left;
  border: 1px solid #1ca3cc;
  border-radius: 20px;
  padding: 10px;
}

.message {
  padding: 5px 10px;
  border-radius: 20px;
  margin: 5px;
  display: inline-block;
  max-width: 80%;
}

.message.user {
  background-color: #1ca3cc;
  margin-left: auto;
}

.message.ai {
  background-color: #333;
}

.input-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background: #0f0f0f;
  background-color: #121212;
  border-radius: 10px;
  margin-bottom: 30px;
}

.input-container input {
  width: 40%;
  padding: 10px;
  margin-right: 10px;
  background-color: #333;
  color: #1ca3cc;
  border-radius: 10px;
}

.input-container button {
  padding: 10px;
  color: #333;
  background-color: #1ca3cc;
  border-radius: 10px;
}

/* Update your existing CSS with the following */

.predictions-container {
  width: 100%; /* Full width */
  max-width: 800px; /* Or the max-width that suits your design */
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  margin-left: 25%;
  margin-top: -30px;
  
}

/* Styling for each team's box within the prediction item */
.team-box {
  position: relative; /* Establish a positioning context for absolute children */
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin: 0 10px;
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid rgb(104, 104, 104);
  border-radius: 10px;
  overflow: hidden; /* Prevents absolute elements from overflowing */
}


/* Shadow and hover effect for team logo */
.team-logo:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 1); /* Shadow effect */
}

/* Styles for the team name pill */
.team-name {
  background-color: #2a2a2a; /* Same color as the item background */
  color: #ffffff;
  padding: 6px 12px;
  border-radius: 20px; /* Rounded pill */
  font-size: 20px;
  font-weight: 600; /* Semi-bold */
  position: relative; /* For shadow and hover effect */
  z-index: 1; /* Ensures the shadow goes under the text */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Shadow for the pill - using pseudo element for more control */
.team-name::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px; /* Same as the pill itself */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6); /* Darker shadow for depth */
  z-index: -1; /* Goes under the pill content */
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 1; /* Fully visible shadow */
}
.pill {
  background-color: #2a2a2a; /* Same color as the team-name pill */
  color: #ffffff;
  padding: 6px 12px;
  border-radius: 20px; /* Rounded pill shape */
  font-size: 15px;
  font-weight: 600; /* Semi-bold */
  position: relative; /* Needed for shadow effect */
  z-index: 1;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: inline-flex; /* Center content */
  justify-content: center;
  align-items: center;
  margin-top: 10px; /* Spacing from the element above */
}

/* Shadow effect for pill */
.pill::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px; /* Same as the pill */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6); /* Shadow effect */
  z-index: -1; /* Shadow appears behind the pill */
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 1; /* Fully visible */
}

/* Hover effect for pill */
.pill:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.pill:hover::before {
  opacity: 0; /* Hide the shadow on hover */
}

.score.pill {
  /* These elements will inherit the pill styles */
  font-size: 1.5pc;
  font-weight: bold;
  margin-top: 15px;
  color: #1ca3cc;
}

.spread.pill{
  font-size: 1pc;
  font-weight: bold;
  margin-top: 10px;
  color: #fff;

}
/* Hover effect for the team name pill */
.team-name:hover {
  transform: scale(1.15); /* Slightly larger pill on hover */
}

.team-name:hover::before {
  opacity: .5; /* Hides the shadow on hover */
}

/* Team logo styling with pill-like circular shape */
.team-logo {
  width: 60px;
  height: 60px;
  background-color: #2a2a2a; /* Same color as the item background */
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6); /* Shadow effect */
  border-radius: 50%; /* Circular shape */
  margin-bottom: 15px;
  
}

.team-logo::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px; /* Same as the pill */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6); /* Shadow effect */
  z-index: -1; /* Shadow appears behind the pill */
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 0; /* Fully visible */
}

/* Individual prediction item styling */
.prediction-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color:  #121212;
  border-radius: 10px;
  /*box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);*/
  width: 100%;
  padding: 20px;
  margin: 10px 0;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

/* Lift and Shadow effect on hover */
.prediction-item:hover {
  transform: translateY(-5px); /* Moves the item up slightly */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5); /* Enhances the shadow for a 'lifted' effect */
  transition: box-shadow 0.8s ease, transform 0.8s ease; /* Smooth transition for the effects */
}


.baseball-icon {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  gap: 10px; /* Adds space between the flex items */
  font-size: 40px;

}

/* Update the win/lose icons position */
.icon.win, .icon.lose {
  font-size: 1.5em;
  margin-top: 10px; /* Spacing from the team name */
}

.icon.icon.win.pill{
  color: #4ac356;
}

.icon.icon.lose.pill{
  color:#E01E5A;
}

/* Loading spinner styles */
.loader {
  margin: 30px 0;
}

/* Media query for smaller screens */
@media (max-width: 600px) {
  .prediction-item {
    flex-direction: column;
  }
  
  .team-box {
    display:  flex;
    margin: 10px 0; /* Vertical spacing for mobile */
    flex-direction: column;
  }

  .baseball-icon {
    display: none; /* Hide the baseball icon on smaller screens */
  }
}

.spinner {
  animation: spin 1s linear infinite;
  font-size: 3rem; /* Adjust size as needed */
  color: #1ca3cc; /* Adjust to match your theme */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.refresh-baseball {
  padding: 1px 2px; /* Small padding */
  font-size: 16px; /* Smaller font size */
  margin: 0px; /* Add some margin around the button */
  background-color: #161616; /* Green background */
  color: #1ca3cc; /* White text */
  border: none; /* Remove border */
  border-radius: 4px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  display: inline-flex; /* Use flexbox for alignment */
  align-items: center; /* Align icon and text vertically */
  justify-content: center; /* Center content horizontally */
  position: relative;
  margin-top: -35px;
  
}

.refresh-baseball:hover {
  color: rgba(3, 242, 255, 0.882); /* Darker shade of green on hover */
}

.refresh-baseball .icon {
  margin-right: 5px; /* Space between icon and text */
}

.score {
  font-weight: bold;
  margin-top: 10px;
  color: #1ca3cc;
   /* Adjust based on your layout */
}

/* Game time styles */
.game-time.pill {
  font-size: 16px; /* Adjust as necessary */
  font-weight: bold;
  margin-bottom: 5px;
}


/* Over/Under total runs styling with pill effect */
.total-runs.pill {
  /* Inherits styles from .pill */
  font-size: 18px; /* Adjust as necessary */
  font-weight: bold; /* Adjust as necessary */
  justify-content: center;
  
}


.team-name-link {
  text-decoration: none; /* Removes underline from links */
  color: inherit; /* Inherits text color from parent */
  display: block; /* Block display to wrap the clickable area around the content */
}

.streak-info {
  position: absolute; /* Absolute positioning relative to team-box */
  top: 10px; /* Adjust as needed */
  left: 10px; /* Adjust as needed */
  background-color: #2a2a2a;
  border-radius: 15px;
  padding: 5px 10px;
  font-weight: bold;
  color: #fff;
  z-index: 2; /* Ensures it's above other content */
}
.streak-win {
  background-color: #28a745; /* Green for win */
}

.streak-loss {
  background-color: #dc3545; /* Red for loss */
}
.team-info-stack {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px; /* Space between items */
}

.parlays-container {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.parlay-item {
  border: 1px solid #1ca3cc;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  display: flex;
  justify-content: space-around;
  width: 90%;
  align-self: center;
}

.team-box {
  display: flex;
  align-items: center;
  margin: 5px;
}

.team-logo {
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
  margin-right: 10px;
  margin-bottom: 10px; /* Space between logo and name */

}

.team-name {
  font-weight: bold;
  margin-bottom: 10px;
}

.parlays-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* This centers the flex items (the rows) on the cross axis */
  justify-content: center; /* This centers the entire container */
  gap: 20px; /* Space between items */
  margin-top: -20px;
}

.parlay-row {
  display: flex;
  justify-content: center; /* Center the items in the row */
  gap: 20px; /* Space between items */
  flex-wrap: wrap; /* Wrap items to next line if not enough space */
}

.chart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px; /* Adjust the space between the selectors and the chart */
  padding: 20px; /* Add padding around the entire container */
}
.selectors-container {
  display: flex;
  justify-content: center;
  gap: 10px; /* Adjust the space between the selectors */
  width: 100%; /* Adjust based on desired width */
}
.team-probability.pill {
  font-size: 1em;
  background: #2a2a2a;
  color: white;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 20px;
  margin-top: 5px; /* Ensure enough space from other elements */
  text-align: center;
}

.faq-container {
  font-family: Arial, sans-serif;
  margin: 20px;
  padding: 20px;
  background-color: #2a2a2a;
  border-radius: 8px;
}

.faq-header {
  color: #1ca3cc;
  text-align: center;
}

.faq h2 {
  color: #1ca3cc;
}

.faq p {
  font-size: 16px;
  color: #f1f1f1;
}

footer p {
  font-size: 14px;
  text-align: center;
  padding: 20px 0;
}

/* .tabs {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.tab-button {
  background-color: #161616;
  color: white;
  border: 2px solid #1ca3cc;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.tab-button:hover, .tab-button.active {
  background-color: #1ca3cc;
  color: #161616;
} */
